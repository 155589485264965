export default {
  setCompanyID(state, payload) {
    state._id = payload._id;
  },
  setCompany(state, payload) {
    state = Object.assign(state, payload);
  },
  setIssuing(state, payload) {
    state = Object.assign(state, payload);
  },
};
