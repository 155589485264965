import ApiService from "@/core/services/ApiService";

import store from "../store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

const totalProgressActivation = async (to, from, next) => {
  const token = localStorage.getItem("progressVerifyToken");
  console.log(token);
  if (token !== "undefined" && token !== "" && token !== null) {
    next();
  } else {
    next("/dashboard");
    Swal.fire({
      title: "Access Denied",
      text: "Please complete your progress!",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "Ok, got it!",
      customClass: {
        confirmButton: "btn fw-semobold btn-light-primary",
      },
    });
    return;
  }
};

const issueCardPackage = async (to, from, next) => {
  const companyID = store.getters["company/companyID"]
    ? store.getters["company/companyID"]
    : localStorage.getItem("companyID");
  localStorage.setItem("companyID", companyID);
  await store.dispatch("company/getSub", companyID).then((res) => {
    if (res.success) {
      if (res.issuing.status === "active") {
        next();
      } else {
        next("/cards/subscription");
        Swal.fire({
          title: "Access Denied",
          text: "Please Activate your subscription to access issuing card!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        });
        return;
      }
    } else {
      next("/cards/subscription");
      Swal.fire({
        title: "Access Denied",
        text: "Please Activate your subscription to access issuing card!",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-semobold btn-light-primary",
        },
      });
      return;
    }
  });
};

const companyDetails = async (to, from, next) => {
  const stripeQuery = to.query.accountId;
  const stripeId = localStorage.getItem("stripeId");

  if (stripeId === stripeQuery) {
    next();
  } else {
    next("/cards/card");
    Swal.fire({
      title: "Access Denied",
      text: "Please make your stripe account first!",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "Ok, got it!",
      customClass: {
        confirmButton: "btn fw-semobold btn-light-primary",
      },
    });
    return;
  }
};

const activeSubscription = async (to, from, next) => {
  /* eslint-disable */
  const company = store.getters["company/getCompany"];
  let currOverallSubsStatus = false;
  let overallSubsStatus = false;

  if (!company._id) {
    const user_id = localStorage.getItem("userID");
    const result = await ApiService.get("company/query?registrar=" + user_id);
    const currentCompany = result?.data?.data[0];

    try {
      const currSubscriptionsStatuses = currentCompany?.allSubscriptions.map(
        (e) => e.status
      );
      currOverallSubsStatus = currSubscriptionsStatuses?.some(
        (val) => val == "active"
      );
    } catch (err) {
      console.log(err);
    }
    try {
      const subscriptionsStatuses = company?.allSubscriptions.map(
        (e) => e.status
      );
      overallSubsStatus = subscriptionsStatuses?.some((val) => val == "active");
      console.log(overallSubsStatus);
    } catch (err) {
      console.log(err);
    }
    if (!currentCompany.subscription?.id || currOverallSubsStatus) {
      next("/billing");
      return;
    }
  } else if (!company?.subscription?.id || overallSubsStatus) {
    next("/billing");
    return;
  }

  next();
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: activeSubscription,
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import("@/views/Documents.vue"),
        beforeEnter: activeSubscription,
      },
      {
        path: "/billing",
        name: "billing",
        component: () => import("@/views/Billing.vue"),
      },
      {
        path: "/cards/card",
        name: "card",
        component: () => import("@/views/cards/Card.vue"),
        beforeEnter: issueCardPackage,
      },
      {
        path: "/cards/issue-card",
        name: "issue-card",
        component: () => import("@/views/cards/IssueCard.vue"),
        beforeEnter: issueCardPackage,
      },
      {
        path: "/cards/edit-connected-account",
        name: "connected-account",
        component: () => import("@/views/cards/EditConnectedAccount.vue"),
        beforeEnter: issueCardPackage,
      },
      {
        path: "/cards/subscription",
        name: "subscription",
        component: () => import("@/views/cards/Subscription.vue"),
        beforeEnter: totalProgressActivation,
      },
      {
        path: "/cards/transaction",
        name: "transaction",
        component: () => import("@/views/cards/Transaction.vue"),
        beforeEnter: issueCardPackage,
      },
      {
        path: "/cards/company-details",
        name: "company-details",
        component: () => import("@/views/cards/CompanyDetails.vue"),
        beforeEnter: companyDetails,
      },
      {
        path: "/transactions",
        name: "transactions",
        component: () => import("@/views/Transaction.vue"),
      },
      {
        path: "/company",
        redirect: "/company/overview",
        name: "profile",
        component: () => import("@/views/layouts/Company.vue"),
        beforeEnter: activeSubscription,
        children: [
          {
            path: "overview",
            name: "company-overview",
            component: () => import("@/views/company/Overview.vue"),
          },
          {
            path: "shareholders",
            name: "company-shareholders",
            component: () => import("@/views/company/Shareholders.vue"),
          },
          {
            path: "services",
            name: "company-services",
            component: () => import("@/views/company/Services.vue"),
          },
          {
            path: "edit",
            name: "company-Edit",
            component: () => import("@/views/company/Settings.vue"),
          },
        ],
      },

      {
        path: "/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        beforeEnter: activeSubscription,
        children: [
          {
            path: "profile",
            name: "account-profile",
            component: () => import("@/views/crafted/account/Profile.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
    ],
    beforeEnter: (to, from, next) => {
      const first = store.getters["auth/isAuthenticated"];
      if (!first) {
        next("/sign-in");
        return;
      }
      store.dispatch("auth/hasExpired").then((response) => {
        if (response) {
          next("/sign-in");
          return;
        }
      });
      next();
    },
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        beforeEnter: (to, from, next) => {
          const first = store.getters["auth/isAuthenticated"];
          if (first) {
            next("/dashboard");
            return;
          }
          store.dispatch("auth/hasExpired").then((response) => {
            if (!response) {
              next("/dashboard");
              return;
            }
          });
          next();
        },
      },
      {
        path: "/sign-up",
        redirect: "/sign-in",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        beforeEnter: (to, from, next) => {
          const first = store.getters["auth/isAuthenticated"];
          if (first) {
            next("/dashboard");
            return;
          }
          store.dispatch("auth/hasExpired").then((response) => {
            if (!response) {
              next("/dashboard");
              return;
            }
          });
          next();
        },
      },
      {
        path: "/verify",
        name: "Verify",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Verify.vue"),
      },
      {
        path: "/verify/service",
        name: "VerifyPayment",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/VerifyPayment.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default routes;
