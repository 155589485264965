
/* eslint-disable */
  (function(d, w, c) {
      (w as any).SibConversationsID = '5eab4e3aaba851952808cb0c';
      w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
      };
      var s = d.createElement('script');
      s.async = true;
      s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
      if (d.head) d.head.appendChild(s);
  })(document, window, 'SibConversations');

import { defineComponent, onMounted, nextTick, ref } from "vue";
import { useStore } from "vuex";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Mutations } from "@/store/enums/StoreEnums";
import KTLoader from "@/components/Loader.vue";
import { loaderEnabled, loaderLogo } from "@/core/helpers/config";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  components: {
    KTLoader,
  },
  setup() {
    const store = useStore();
    const loading = ref(true);

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });

      await store.dispatch("auth/tryLogin");
      await store.dispatch("company/getCompany");
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");

      loading.value = false;
    });
    return {
      loaderLogo,
      loaderEnabled,
      loading,
    };
  },
});


