import ApiService from "@/core/services/ApiService";

let ip;
if (process.env.DEV) {
  ip = process.env.VUE_APP_API_URL;
} else {
  ip = process.env.VUE_APP_API_URL;
}
export default {
  async getPackage(context) {
    let url = `${ip}/api/package`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      let error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    context.commit("setCompany", responseData.company || {});

    return responseData;
  },
  async postSignatureDocument(context, payload) {
    let url = `${ip}/api/documents/signature`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async postDocument(context, payload) {
    let url = `${ip}/api/documents`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async buyService(context, payload) {
    let url = `${ip}/api/company/buy-service`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async confirmService(context, payload) {
    let url = `${ip}/api/company/confirm-service`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async deactivateCompany(context, payload) {
    let url = `${ip}/api/deactivate/company`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getSummary() {
    let url = `${ip}/api/summary`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getDocuments() {
    let url = `${ip}/api/documents`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getShareholders() {
    let url = `${ip}/api/shareholders`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getServices() {
    let url = `${ip}/api/services`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getCompany(context) {
    const user_id = localStorage.getItem("userID");
    if (!user_id) return;
    const result = await ApiService.get("company/query?registrar=" + user_id);
    if (result.data?.data?.length) {
      context.commit("setCompany", { ...result.data?.data[0] });
    }
    return;
  },
  async update(context, payload) {
    context.commit("setCompany", { ...payload });
  },

  async updateIssuing(context, payload) {
    context.commit("setIssuing", { ...payload });
  },

  async payVirtualFees(context, payload) {
    let url = `${ip}/api/company/buy-cards`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company: payload.companyId,
        amount: payload.amount,
        user: payload.userId,
        paymentMethodId: payload.id,
        customerId: payload.customer_id,
        brand: payload.brand,
        last4: payload.last4,
        cadence: "pending",
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async issueNewVirtualCard(context, payload) {
    let url = `${ip}/api/company/issue_new_virtual_card`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company: payload.companyId,
        amount: payload.amount,
        user: payload.userId,
        paymentMethodId: payload.id,
        customerId: payload.customer_id,
        brand: payload.brand,
        last4: payload.last4,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async sendEmailConnected(context, payload) {
    let url = `${ip}/api/company/send-success-email`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: payload.fullName,
        email: payload.email,
        companyId: payload.companyId,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async createCompanyStripe(context, payload) {
    let url = `${ip}/api/company/card/details`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        companyId: payload.companyId,
        businessType: payload.businessType,
        businessStructure: payload.businessStructure,
        legalBusiness: payload.legalBusiness,
        crn: payload.crn,
        doingBusiness: payload.doingBusiness,
        companyLine1: payload.companyline1,
        companyLine2: payload.companyline2,
        companyCity: payload.companycity,
        companyPostalCode: payload.companypostalCode,
        companyPhone_number: `${payload.phoneCode}${payload.companyPhone_number}`,
        industry: payload.industry,
        website: payload.website,
        firstname: payload.firstname,
        lastname: payload.lastname,
        email: payload.email,
        jobTitle: payload.jobTitle,
        dateBirth: payload.dateBirth,
        homeAddress: payload.registeredhomeAddress,
        homeLine1: payload.homeLine1,
        homeLine2: payload.homeLine2,
        homeCity: payload.homeCity,
        homePostalCode: payload.homePostalCode,
        homePhone_number: `${payload.homePhoneCode}${payload.homePhone_number}`,
        PercentOwner: payload.PercentOwner,
        directorFirstname: "",
        directorLastname: "",
        directorEmail: "",
        passportOrDriverLicense: payload.link,
        bankStatementOrUtilityBill: payload.link2,
        bankAccountName: payload.bankAccountName,
        bankSortCode: payload.bankSortCode,
        bankAccountNumber: payload.bankAccountNumber,
        bankBuildingSocityRollNumber: payload.bankBuildingSocityRollNumber,
        hasOwn25: payload.hasOwn25,
        memberCompany: payload.memberCompany,
        own25: payload.own25,
        country: payload.registeredBusinessAddress,
        countryCode: payload.countryCode,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getCard(context, payload) {
    let url = `${ip}/api/company/get-card?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getCardStatus(context, payload) {
    let url = `${ip}/api/get-card?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getCardBalance(context, payload) {
    let url = `${ip}/api/retrieve-balance?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    if (response.status == 400) {
      return;
    }
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async payPhysicalCard(context, payload) {
    let url = `${ip}/api/request-physical-card/pay-fees`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        company: payload.company,
        amount: payload.amount,
        shipping: payload.shipping,
        user: payload.user,
        customerId: payload.customer_id,
        paymentMethodId: payload.id,
        brand: payload.brand,
        last4: payload.last4,
        name: `${payload.firstName} ${payload.lastName}`,
        addressLine1: payload.addressLine1,
        addressLine2: payload.addressLine2,
        state: payload.state,
        city: payload.city,
        zipcode: payload.zipcode,
        country: payload.country,
        service: payload.service,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async createFunds(context, payload) {
    let url = `${ip}/api/create-funds`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        companyId: payload.companyId,
        amount: payload.amount,
        userId: payload.userId,
        customerId: payload.customer_id,
        paymentMethodId: payload.id,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getTx(context, payload) {
    let url = `${ip}/api/company-card-transaction?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getCardsTx(context, payload) {
    let url = `${ip}/api/get-authorizations?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getSub(context, payload) {
    let url = `${ip}/api/get-issuing-card-subscription?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async updateSubscription(context, payload) {
    let url = `${ip}/api/update-card-subscription`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        companyId: payload.companyId,
        email: payload.email,
        name: payload.name,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },
  async checkIssuing(context, payload) {
    let url = `${ip}/api/check-issuing-card?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async replaceCard(context, payload) {
    let url = `${ip}/api/replace-card?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getBillingDetails(context, payload) {
    let url = `${ip}/api/get-billing-details?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async cancelCard(context, payload) {
    let url = `${ip}/api/cards/cancel-card`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: payload.cardData,
        companyId: payload.companyId,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }

    return responseData;
  },

  async activityLog(context, payload) {
    let url = `${ip}/api/activity-log?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }

    return responseData;
  },

  async getTopup(context, payload) {
    let url = `${ip}/api/get-topup?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }

    return responseData;
  },
};
