export default {
  companyID(state) {
    return state._id;
  },
  getCompany(state) {
    return state;
  },
  getSubscription(state) {
    return state.subscription;
  },
};
