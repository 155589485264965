export default {
  userID(state) {
    return state.userID;
  },
  paymentMethod(state) {
    return state.paymentMethod;
  },
  isVerified(state) {
    return state.isVerified;
  },
  firstName(state) {
    return state.firstName;
  },
  lastName(state) {
    return state.lastName;
  },
  email(state) {
    return state.email;
  },
  image(state) {
    return state.image;
  },
  role(state) {
    return state.role;
  },
  phone(state) {
    return state.phone;
  },
  loadChat(state) {
    return state.loadChat;
  },
  phoneCode(state) {
    return state.phoneCode;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    if (state.token) {
      return true;
    } else {
      return false;
    }
  },
  company(state) {
    return state.company;
  },
  hasCompany(state) {
    if (
      (state.role === "user" && state.company === "null") ||
      (state.role === "user" && !state.company) ||
      (state.role === "user" && state.company === null)
    ) {
      return false;
    } else {
      return true;
    }
  },
  isAdmin(state) {
    if (state.role === "admin" || state.role === "Super admin") {
      return true;
    } else {
      return false;
    }
  },
  didAutoLogout(state) {
    return state.autoLogout;
  },

  progressVerifyToken(state) {
    return state.progressVerifyToken;
  },
};
