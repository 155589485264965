import ApiService from "@/core/services/ApiService";

let timer;
let ip;
if (process.env.DEV) {
  ip = process.env.VUE_APP_API_URL;
} else {
  ip = process.env.VUE_APP_API_URL;
}
export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },
  async tryLogin(context) {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userID");
    const role = localStorage.getItem("role");
    const phone = localStorage.getItem("phone");
    const phoneCode = localStorage.getItem("phoneCode");
    const email = localStorage.getItem("email");
    const image = localStorage.getItem("image");
    const company = localStorage.getItem("company");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    const isVerified = localStorage.getItem("isVerified") == "true";
    const paymentMethod =
      localStorage.getItem("paymentMethod") != "undefined"
        ? JSON.parse(localStorage.getItem("paymentMethod"))
        : null;

    if (!token) {
      console.log("token not found");
      return;
    }
    const expiresIn = +parseInt(tokenExpiration) - new Date().getTime();

    if (expiresIn < 0) {
      console.log("expired");
      return;
    }

    timer = setTimeout(() => {
      context.dispatch("autoLogout");
    }, expiresIn);

    if (token && userID) {
      context.commit("setUser", {
        id: userID,
        token,
        firstName,
        lastName,
        phone,
        phoneCode,
        company,
        email,
        role,
        image,
        isVerified,
        paymentMethod,
      });

      const result = await ApiService.get("user?_id=" + userID);
      if (result.data) {
        const { data: user } = result.data;
        context.commit("setUser", {
          id: userID,
          token,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          phoneCode: user.phoneCode,
          company: user.company,
          email: user.email,
          role: user.role,
          image: user.image,
          isVerified: user.isVerified,
          paymentMethod: user.paymentMethod,
        });
      }

      return;
    }
  },
  async hasExpired(context) {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    const expiresIn = +parseInt(tokenExpiration) - new Date().getTime();

    if (expiresIn < 0) {
      context.dispatch("logout");
      return true;
    }
    return false;
  },
  async logout(context) {
    let url;
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (role === "user") {
      url = `${ip}/auth/logout`;
    } else if (role === "sales rep") {
      url = `${ip}/auth/logout/salesrep`;
    } else {
      url = `${ip}/api/admin/logout`;
    }
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("company");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("phone");
    localStorage.removeItem("image");
    localStorage.removeItem("phoneCode");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("isVerified");
    localStorage.clear();
    clearTimeout(timer);
    context.commit("setUser", {
      token: null,
      id: null,
      role: null,
      email: null,
      image: null,
      phone: null,
      phoneCode: null,
      firstName: null,
      company: null,
      lastName: null,
    });
    return;
  },
  async auth(context, payload) {
    let url = `${ip}/auth/login`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    const expiresIn = +responseData.expiresIn * 1000;
    const expirationDate = new Date().getTime() + expiresIn;
    localStorage.setItem("isVerified", responseData.isVerified);
    localStorage.setItem("token", responseData.token);
    localStorage.setItem("userID", responseData.id);
    localStorage.setItem("role", responseData.role);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("email", responseData.email);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("phone", responseData.phone);
    localStorage.setItem("phoneCode", responseData.phoneCode);
    localStorage.setItem("company", responseData.company);
    localStorage.setItem("tokenExpiration", expirationDate);
    localStorage.setItem(
      "progressVerifyToken",
      responseData.progressVerifyToken
    );
    responseData.paymentMethod
      ? localStorage.setItem(
          "paymentMethod",
          JSON.stringify(responseData.paymentMethod)
        )
      : "";

    timer = setTimeout(() => {
      context.dispatch("autoLogout");
    }, expiresIn);

    context.commit("setUser", {
      ...responseData,
    });
  },
  async deactivateAccount(context, payload) {
    let url = `${ip}/auth/deactivate/account`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async updateProfile(context, payload) {
    let url = `${ip}/auth/update`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    localStorage.setItem("userID", responseData.id);
    localStorage.setItem("role", responseData.role);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("email", responseData.email);
    localStorage.setItem("phone", responseData.phone);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("phoneCode", responseData.phoneCode);
    localStorage.setItem("company", responseData.company);

    context.commit("setUser", {
      ...responseData,
      token: token,
    });

    return;
  },
  async updateCompany(context, payload) {
    let url = `${ip}/api/update-company`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async updateEmail(context, payload) {
    let url = `${ip}/auth/update/email`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    localStorage.setItem("userID", responseData.id);
    localStorage.setItem("role", responseData.role);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("email", responseData.email);
    localStorage.setItem("phone", responseData.phone);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("phoneCode", responseData.phoneCode);
    localStorage.setItem("company", responseData.company);

    context.commit("setUser", {
      ...responseData,
      token: token,
    });

    return;
  },
  async updatePassword(context, payload) {
    let url = `${ip}/auth/update/password`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return;
  },
  async buyPackage(context, payload) {
    let url = `${ip}/api/company/buy-package`;
    const details = {
      ...payload,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData.link;
  },
  async paymentVerify(context, payload) {
    let url = `${ip}/api/verify-payment/service`;
    const details = {
      ...payload,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async sendMessage(context, payload) {
    const token = localStorage.getItem("token");
    let url = `${ip}/api/message`;
    const details = {
      ...payload,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(details),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async toggleChat(context) {
    context.commit("toggleChat");
  },
  async loadMessages() {
    const token = localStorage.getItem("token");
    let url = `${ip}/auth/messages/all`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async forgotPassword(context, payload) {
    let url = `${ip}/auth/forgot-password`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ ...payload }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to update password"
      );
      throw error;
    }

    return responseData;
  },
  async resetPassword(context, payload) {
    let url = `${ip}/auth/reset-password/reset`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ ...payload }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to update password"
      );
      throw error;
    }

    return responseData;
  },
  async resetPasswordVerify(context, payload) {
    let url = `${ip}/auth/reset-password/verify?tkn=${payload.tkn}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to update password"
      );
      throw error;
    }

    return responseData;
  },
  async changePassword(context, payload) {
    let url;

    if (payload.role === "owner") {
      url = `${ip}/auth/update`;
    } else if (payload.role === "sales rep") {
      url = `${ip}/auth/update/salesrep`;
    } else {
      url = `${ip}/api/admin/update`;
    }
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password: payload.password,
        oldPassword: payload.oldPassword,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return;
  },
  async countNotifications() {
    let url = `${ip}/auth/notifications/count`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async messageRead(context, payload) {
    let url = `${ip}/auth/messages/read/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return;
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
  async updateContext(context, payload) {
    if (payload.paymentMethod)
      localStorage.setItem(
        "paymentMethod",
        JSON.stringify(payload.paymentMethod)
      );

    context.commit("setUser", { ...payload });
  },

  sendProgressMsg(context, payload) {
    context.commit("setTotalProgress", { ...payload });
  },

  async sendMessageForCard(context, payload) {
    let url = `${ip}/auth/progress/verify?id=${payload.userId}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    context.commit(
      "setProgressVerifyToken",
      responseData.success.verifyProgressToken || {}
    );
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to send Email");
      throw error;
    }
    return responseData;
  },

  async getProduct() {
    let url = `${ip}/api/get-product`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to send Email");
      throw error;
    }
    return responseData;
  },

  async deductPayment(context, payload) {
    let url = `${ip}/api/check-payment?user_id=${payload.user_id}&subscription_plan=${payload.subscription_plan}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },

  async getFees() {
    let url = `${ip}/api/get-fees`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },

  async getEphemeralKeyResult(context, payload) {
    let url = `${ip}/api/ephemeral-keys`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        card_id: payload.cardId,
        nonce: payload.nonce,
        accountStripeId: payload.accountStripeId,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },

  async getConnectedAccountDB(context, payload) {
    let url = `${ip}/api/connected-account/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },

  async updateConnectedAccount(context, payload) {
    let url = `${ip}/api/update-connected-account/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
};
